import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Script, ScriptStrategy } from 'gatsby'
import { Button } from '../components/Button'
import { ContactLink } from '../components/LinkCoponents'
import config from '../../data/SiteConfig'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../layout'))
const Accordion = loadable(() => import('../components/Accordion'))

const posterContainerStyle = { marginBottom: '20px' }

const ShitHappens = ({ location }) => {
  const [status, setStatus] = useState('')
  const [expanded, setExpanded] = useState(1)

  const submitForm = (ev) => {
    ev.preventDefault()
    const form = ev.target
    const formData = new FormData(form)
    const xhr = new XMLHttpRequest()
    if (form && form.method && form.action) {
      xhr.open(form.method, form.action)
      xhr.setRequestHeader('Accept', 'application/json')
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return
        if (xhr.status === 200) {
          form.reset()
          setStatus('SUCCESS')
        } else {
          setStatus('ERROR')
        }
      }
      xhr.send(formData)
    } else {
      console.error('Invalid form')
    }
  }

  const seoDescription =
    "It's a podcast where we discuss and share amazing, empowering, unusual and sometimes funny things you have done to deal with shitty things in your life"
  const seoTitle = `Shit Happens By ${config.siteTitle}`
  return (
    <Layout
      title={seoTitle}
      seoDescription={seoDescription}
      seoImage={config.podcastCover}
      href={location.href}
    >
      <title>{seoTitle}</title>
      <Script src="https://www.google.com/recaptcha/api.js" strategy={ScriptStrategy.idle} />
      <div className="container">
        <div style={posterContainerStyle}>
          <StaticImage
            src="../../static/images/etc/podcast-cover.webp"
            alt="podcast cover"
            title="podcast cover"
            className="w-100"
            placeholder="blurred"
          />
        </div>
        <h1>Shit Happens - Podcast</h1>
        <Accordion
          header="How this works?"
          panelId={1}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          Ashmeet Sehgal, your host, with help of his guests is looking at stories of shitty things
          that happened in your life and the amazing, empowering, unusual and sometimes funny things
          you have done as a result of going through that, People send their stories on this portal.
          [Form Below] Breakups, life transitions, mental/emotional health, career, health and loads
          more. With interviews, anecdotes, advice and thoughts. Some shit happened, Need a
          solution?? try us.
          <br />
          Listen and subscribe to the show and never feel broken or like you’re the only one ever
          again.
        </Accordion>
        <Accordion header="FAQs" panelId={2} expanded={expanded} setExpanded={setExpanded}>
          <ul>
            <li>
              <div>
                <span className="b">Question:</span>{' '}
                <span className="i">Are you collecting any information from the below form?</span>
              </div>
              <div>
                <span className="b">Answer:</span>{' '}
                <span>
                  No, This form is totally anonymous, and even if you put any information by
                  mistake, that will be edited out in the podcast
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="b">Question:</span>{' '}
                <span className="i">How can we subscribe to this podcast?</span>
              </div>
              <div>
                <span className="b">Answer:</span>{' '}
                <span>
                  This will be hosted on Spotify, Google Podcast, Apple Podcast, SoundCloud. You can
                  subscribe us at your favorite platform.
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="b">Question:</span>{' '}
                <span className="i">Can I become guest on this Podcast?</span>
              </div>
              <div>
                <span className="b">Answer:</span>{' '}
                <span>
                  Yes, You can simply <ContactLink name="contact me" />
                </span>
              </div>
            </li>
          </ul>
        </Accordion>
        <form onSubmit={submitForm} action="https://formspree.io/f/myybqkpv" method="POST">
          <label aria-label="story" htmlFor="story">
            Tell us your story
          </label>
          <textarea
            id="story"
            aria-label="message"
            rows={20}
            placeholder="don't worry, it's anonymous. E.g. I am 27 male, and this is my story"
            type="text"
            name="message"
          />
          <div className="g-recaptcha" data-sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_KEY} />
          {status === 'SUCCESS' ? (
            <p>Thanks! Your response is submitted, See you at the podcast</p>
          ) : (
            <Button>Submit</Button>
          )}
          {status === 'ERROR' && <p>Oops! There was an error.</p>}
        </form>
      </div>
    </Layout>
  )
}

export default ShitHappens
